






























































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BasePageHeader from '@improve/common-components/src/components/widgets/BasePageHeader.vue';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseUserAvatar from '@improve/common-components/src/components/widgets/BaseUserAvatar.vue';
import BasePill from '@improve/common-components/src/components/widgets/BasePill.vue';
import StatsBlock from '@improve/common-components/src/components/widgets/StatsBlock.vue';
import Team, { TEAM_TYPE } from '@improve/common-utils/src/model/Team';
import lightbulbIcon from '@improve/common-components/src/assets/lightbulb.svg';
import genericUser from '@improve/common-components/src/assets/generic_user.svg';
import StatsData from '@improve/common-utils/src/model/StatsData';
import BaseTabOption from '@improve/common-utils/src/types/TabOption';
import User from '@improve/common-utils/src/model/User';

@Component({
  name: 'TeamDetails',
  components: {
    BasePageHeader,
    BaseUserAvatar,
    BaseButton,
    BasePill,
    StatsBlock
  }
})
export default class TeamDetails extends Vue {
  @Getter allTeamsById!: (id: string) => Team;

  @Getter teamStatsByID!: Map<string, StatsData>;

  @Action fetchTeamStats!: ActionMethod;

  @Action fetchTeamMembers!: ActionMethod;

  @Action fetchUsersByBatch!: ActionMethod;

  teamMembers: Array<User> = [];

  lightBulbIcon = lightbulbIcon;

  genericUser = genericUser;

  options: Array<BaseTabOption> = [];

  get team(): Team {
    return this.allTeamsById && this.allTeamsById(this.$route.params.id);
  }

  get currentTab(): string {
    return this.$route.name || '';
  }

  get isVirtualTeam(): boolean {
    return this.team?.type === TEAM_TYPE.VIRTUAL_TEAM;
  }

  async created(): Promise<void> {
    await this.fetchTeamStats([this.team.id]);
    await this.fetchTeamSubscriptions();
    this.initTabs();
  }

  async fetchTeamSubscriptions(): Promise<void> {
    if (!this.team || !this.team.id) {
      return;
    }
    const subs: Map<string, number> = await this.fetchTeamMembers(this.team.id);
    this.teamMembers = await this.fetchUsersByBatch(User.getUserIdsByRole(subs));
  }

  getTeamStats(teamId: string): StatsData {
    return this.teamStatsByID && this.teamStatsByID.has(teamId)
      ? this.teamStatsByID.get(teamId)!
      : new StatsData();
  }

  initTabs(): void {
    this.options = [
      {
        title: this.$t('page.teamDetails.tabs.teamImproves').toString(),
        value: 'TeamImprovesTab'
      },
      {
        title: this.$t('page.teamDetails.tabs.teamMembers').toString(),
        value: 'TeamMembersTab'
      }
    ];
  }

  editVirtualTeam(): void {
    this.$router.push({
      name: 'EditVirtualTeam',
      params: { id: this.team.id! }
    });
  }

  goToTab(tabValue: string): void {
    if (tabValue !== this.currentTab) {
      this.$router.push({ name: tabValue });
    }
  }
}
