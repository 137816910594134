import { render, staticRenderFns } from "./TeamDetails.vue?vue&type=template&id=12ee506a&scoped=true&"
import script from "./TeamDetails.vue?vue&type=script&lang=ts&"
export * from "./TeamDetails.vue?vue&type=script&lang=ts&"
import style0 from "./TeamDetails.vue?vue&type=style&index=0&id=12ee506a&lang=scss&scoped=true&"
import style1 from "./TeamDetails.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ee506a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAvatar,VCol,VIcon,VRow,VSpacer})
